
import { defineComponent, ref, onMounted } from 'vue';
import { CountTo } from 'vue3-count-to';

import { getChannelTodayRecord } from '@/services/api';

export default defineComponent({
  components: {
    CountTo
  },
  setup() {
    const todayRecord = ref({});

    onMounted(async() => {
      const { data } = await getChannelTodayRecord();
      todayRecord.value = data;
    });

    return {
      todayRecord
    };
  }
});
