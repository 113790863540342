
import { nextTick, defineComponent, onActivated, onBeforeUnmount, onDeactivated, onMounted, PropType, ref, toRefs, watch } from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';

import { getUserLineChartRecords } from '@/services/api';
import dayjs from 'dayjs';

const formatTime = (time: string) => {
  if (!time) return;

  const TIME_FORMAT = 'YYYY-MM-DD';
  return dayjs(time).format(TIME_FORMAT);
};

const DASHBOARD_DATA = {
  date: [],
  // eslint-disable-next-line @typescript-eslint/camelcase
  subscribers_count: [],
  // eslint-disable-next-line @typescript-eslint/camelcase
  visitors_count: []
};

export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const { dateRange } = toRefs(props);
    const dateValues = ref(dateRange.value);
    watch(dateRange, (dateRange) => {
      (dateValues.value) = dateRange;
    });

    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize();

    const dashboardData = ref(DASHBOARD_DATA);

    const now = dayjs().format('YYYY.MM.DD');
    const sixDaysBefore = dayjs().subtract(6, 'day').format('YYYY.MM.DD');

    const getDashboardData = async() => {
      const { data } = await getUserLineChartRecords({
        query: {
          ...(dateRange.value && {
            date: `${dateRange.value[0]} ${dateRange.value[1]}`
          })
        }
      });
      dashboardData.value = data;
    };

    const setOptions = () => {
      const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
      unwarp(chart.value).setOption({
        xAxis: {
          data: dashboardData.value.date,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis'
          // axisPointer: {
          //   type: 'cross'
          // },
          // padding: 8
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: {
          data: ['Total Visitors', 'Total Subscribers']
        },
        series: [{
          name: 'Total Visitors',
          itemStyle: {
            // color: '#FF005A'
            lineStyle: {
              // color: '#FF005A',
              width: 2
            }
          },
          smooth: false,
          type: 'line',
          data: dashboardData.value.visitors_count,
          animationDuration: 1000,
          animationEasing: 'cubicInOut'
        }, {
          name: 'Total Subscribers',
          itemStyle: {
            lineStyle: {
              width: 2
            }
          },
          smooth: false,
          type: 'line',
          data: dashboardData.value.subscribers_count,
          animationDuration: 1000,
          animationEasing: 'cubicInOut'
        }]
      } as EChartsOption);
    };

    watch([dateRange, dashboardData], () => {
      setOptions();
    });

    const initChart = () => {
      const lineChart = init(document.getElementById('userLineCharts') as HTMLDivElement, 'macarons');
      chart.value = lineChart;
    };

    onMounted(() => {
      mounted();
      getDashboardData();
      nextTick(() => {
        initChart();
        setOptions();
      });
    });

    onBeforeUnmount(() => {
      beforeDestroy();
      if (!chart.value) {
        return;
      }
      chart.value.dispose();
      chart.value = null;
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
      formatTime
    };
  }
});
