
import {
  defineComponent,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
  nextTick,
  watch,
  ref
} from 'vue';
import resize from '@/components/charts/mixins/resize';
import { init, EChartsOption } from 'echarts';

import { getChannelTypePieChart, ChannelPieChartRecordsResponse } from '@/services/api';
import dayjs from 'dayjs';

export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    },
    /**
     * select_date
     * @type Array [endDate,startDate]
     */
    selectDate: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const { mounted, chart, beforeDestroy, activated, deactivated } = resize();
    const data = ref([]);
    const now = dayjs().format('YYYY.MM.DD');
    const sixDaysBefore = dayjs().subtract(6, 'day').format('YYYY.MM.DD');

    watch(() => props.selectDate, async(selectDate) => {
      if (selectDate.length === 0) return;
      const res = await getChannelTypePieChart({
        query: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          start_date: `${selectDate[1]}`,
          // eslint-disable-next-line @typescript-eslint/camelcase
          end_date: `${selectDate[0]}`
        }
      });
      data.value = res.data;
    },
    {
      immediate: true,
      deep: true
    });

    const setOptions = async() => {
      chart.value.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'horizontal'
        },
        series: [
          {
            top: 50,
            name: 'Channel Type',
            type: 'pie',
            data: data.value || [],
            animationEasing: 'cubicInOut',
            animationDuration: 2000
          }
        ]
      } as EChartsOption);
    };

    watch(data, () => {
      setOptions();
    });

    const initChart = async() => {
      const pieChart = init(
        document.getElementById('homePieChartsChannelType') as HTMLDivElement,
        'macarons'
      );
      chart.value = pieChart;
    };

    onMounted(() => {
      mounted();
      nextTick(() => {
        initChart();
        setOptions();
      });
    });

    onBeforeUnmount(() => {
      // if (!chart.value) {
      //   return
      // }
      // chart.value.dispose()
      // chart.value = null
      beforeDestroy();
    });

    onActivated(() => {
      activated();
    });

    onDeactivated(() => {
      deactivated();
    });

    return {
      now,
      sixDaysBefore
    };
  }
});
