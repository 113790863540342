
import { getChannelRecord, DashboardChannelListResponse } from '@/services/api';
import { defineComponent, ref, toRefs, onMounted, watch, PropType } from 'vue';
import dayjs from 'dayjs';

const formatTime = (time: string) => {
  if (!time) return;

  const TIME_FORMAT = 'YYYY-MM-DD';
  return dayjs(time).format(TIME_FORMAT);
};

export default defineComponent({
  props: {
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  setup(props) {
    const { dateRange } = toRefs(props);

    const page = ref(1);
    const data = ref<DashboardChannelListResponse>({});
    const dateValues = ref(dateRange.value);
    watch(dateRange, (dateRange) => {
      (dateValues.value) = dateRange;
    });

    const fetchChannelRecord = async() => {
      const res = await getChannelRecord({
        query: {
          page: page.value,
          ...(dateRange.value && {
            date: `${dateRange.value[0]} ${dateRange.value[1]}`
          })
        }
      });

      data.value = res;
    };

    watch([dateRange, page], () => {
      fetchChannelRecord();
    });

    onMounted(async() => {
      fetchChannelRecord();
    });

    return {
      page,
      data,
      formatTime
    };
  }
});
