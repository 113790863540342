
import { ref } from 'vue';
import PanelGroup from './components/PanelGroup.vue';
import LineChartRevenue from './components/LineChartRevenue.vue';
import LineChartUser from './components/LineChartUser.vue';
import PieChartUser from './components/PieChartUser.vue';
import PieChartRevenue from './components/PieChartRevenue.vue';
import ChannelDetail from './components/ChannelDetail.vue';
import DateRangePicker from '@/components/date-range-picker/Index.vue';
import dayjs from 'dayjs';
import PieChartChannels from './components/PieChartChannels.vue';
import DaysSelect from './components/daysSelect.vue';

export default {
  components: {
    PanelGroup,
    LineChartRevenue,
    LineChartUser,
    PieChartUser,
    PieChartRevenue,
    ChannelDetail,
    DateRangePicker,
    PieChartChannels,
    DaysSelect
  },
  setup() {
    const dateRangeValue = ref(null);
    const defaultStartTime = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
    const defaultEndTime = dayjs().format('YYYY-MM-DD');
    const dateRange = ref([defaultStartTime, defaultEndTime]);

    const setDateRange = (date: any) => {
      dateRange.value = [];
      date.forEach(element => {
        dateRange.value.push(element);
      });
    };

    const ChannelTypeDaysSelect = ref([]);
    return {
      dateRangeValue,
      dateRange,
      setDateRange,
      ChannelTypeDaysSelect
    };
  }
};
